.vertical-layout-header-width {
    width: 40%;
}

.vertical-align {
    vertical-align: middle;
}

.editable-table-text-align-left {
    text-align: left;
}

.editable-table-text-align-right {
    text-align: right;
}
