

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/* @media (prefers-color-scheme: light) {
  html {
    color-scheme: light;
  }
  body {
    color: white;
    background: black;
  }
}*/

.text-underlined {
  text-decoration: underline;
}

.listing-divider {
  height: 2px;
  background-color: #eaeaea;
}

.inline-icon {
  color: black;
}

.inline-icon:hover {
  color: #14A44D;
}

.nopad {
  padding: 0;
  margin: 0;
}

.color-proceed {
  color: #14A44D;
}

.color-cancel {
  color: red;
}

.color-error {
  color: red;
}

.color-highlight {
  background-color: #aaf5b4;
}

.table-collapsed {
  border-collapse: collapse;
}

.table-border{
  border: 10px solid #000000;
}

.nobullets {
  list-style-type: none;
}

.padded-icon {
  padding-left: 2px;
  padding-right: 2px;
}

.elementFadeInAndOut {
  opacity:1;
  animation: fade 2s linear 1 forwards;
}

@keyframes fade {
  0%, 100% { opacity: 0}
  50% { opacity: 1}
}

.link {
  cursor: pointer;
  text-decoration: underline;
}

.link:hover {
  color: blue;
  font-size: 100%
}



