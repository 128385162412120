.accountgroups-left-pane {
    background-color: rgb(219, 221, 222);
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    border-color: #14A44D;
}

.accountgroups-right-pane {
    background-color: rgb(205, 204, 207);
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    border-color: #14A44D;
}

.accountgroups-panes {
    height: 1200px;
}

.accountgroup-list {
    color: black;
    list-style-type: none; /* Remove the bullets */
    padding: 0;
    margin: 0;
}

.accountgroup-list-active {
    color:#14A44D;
    list-style-type: none; /* Remove the bullets */
    padding: 0;
    margin: 0;
}

.accountgroup-list:hover {
    color:#14A44D
}

.account-list {
    color: black;
    padding: 0;
    margin: 0;
}

.account-list-first-line {
    color: black;
    display:flex;
    flex-direction: row;
}

.account-transfer-card-header-bg {
    background-color: cyan;
}



