.modal-list {
    color: black;
    padding: 0;
    margin: 0;
}

.modal-list:hover {
    background-color: #14A44D;
    color: black;
    padding: 0;
    margin: 0;
}