.submitButton-customizable {
    background-color: #14A44D;
}

:root {
    --amplify-colors-brand-primary-10: hsla(144, 78%, 5%, 1);
    --amplify-colors-brand-primary-20: hsla(144, 78%, 10%, 1);
    --amplify-colors-brand-primary-40: hsla(144, 78%, 15%, 1);
    --amplify-colors-brand-primary-60: hsla(144, 78%, 20%, 1);
    --amplify-colors-brand-primary-80: hsla(144, 78%, 25%, 1);
    --amplify-colors-brand-primary-90: hsla(144, 78%, 30%, 1);
    --amplify-colors-brand-primary-100: hsla(144, 78%, 35%, 1);
}