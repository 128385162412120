.baskets-left-pane {
    background-color: rgb(219, 221, 222);
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    border-color: #14A44D;
}

.baskets-right-pane {
    background-color: rgb(205, 204, 207);
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    border-color: #14A44D;
}

.basket-panes {
    height: 1200px;
}

.basket-list {
    color: black;
    list-style-type: none; /* Remove the bullets */
    padding: 0;
    margin: 0;
}

.basket-list-active {
    color:#14A44D;
    list-style-type: none; /* Remove the bullets */
    padding: 0;
    margin: 0;
}

.basket-list:hover {
    color:#14A44D
}

.ticker-list {
    color: black;
    list-style-type: none; /* Remove the bullets */
    padding: 0;
    margin: 0;
}

.ticker-list:hover {
    background-color: #14A44D;
    color: black;
    list-style-type: none; /* Remove the bullets */
    padding: 0;
    margin: 0;
}

.dropdown-invalid {
    background-color: #14A44D;
}

.itemHighlighted {
    color: #14A44D;
}
