.simulations-left-pane {
    background-color: rgb(219, 221, 222);
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    border-color: #14A44D;
}

.simulations-right-pane {
    background-color: rgb(205, 204, 207);
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;
    border-color: #14A44D;
}

.simulations-panes {
    height: 1200px;
}

.simulations-list {
    color: black;
    list-style-type: none; /* Remove the bullets */
    padding: 0;
    margin: 0;
}

.simulations-list-active {
    color:#14A44D;
    list-style-type: none; /* Remove the bullets */
    padding: 0;
    margin: 0;
}

.simulations-list:hover {
    color:#14A44D
}

.simulations-list {
    color: black;
    padding: 0;
    margin: 0;
}

.results-slider {
    max-width: 100%;
    margin: auto;
}

.results-slider-track.results-slider-track-0 {
   top: 7px;
   height: 5px;
   background: #14A44D;
}

.results-slider-track.results-slider-track-1 {
    top: 7px;
    height: 5px;
    background: gray;
 }

.result-slider-thumb {
    cursor:pointer;
    background: #14A44D;
    width: 5px;
    height: 20px;
    outline: none;
    /*top: 15px;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top: 20px;
    border-bottom: 20px solid #14A44D;
    */
}

.result-slider-mark {
    cursor:pointer;
    top:6px;
    width: 1.5px;
    height: 8px;
    background-color: gray;
}

.result-slider-mark.result-slider-mark-before {
    background-color: #14A44D;
}

.result-slider-mark.result-slider-mark-after {
    display: none;
}

.table-border-visible {
    border-right: 2px solid;
}

