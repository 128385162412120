
body {
    background-color: rgb(243, 240, 236);
}

.wheelrotationbackground {
    position: relative;
    background-image: url("/images/wheelrotation.svg");
    background-size: 100px 100px;
    background-repeat: 'repeat';
    height: 100vh;
    width:100%;
}

.wheelrotationbackground::before {
    background-color: rgb(243, 240, 236);;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left:0px;
}